import './MainNav.css';
import { Link } from "react-router-dom";
import nayanlogo from "../../assets/logos/nayanlogo.svg";
import instagram from '../../assets/icons/instagram1.png';
import whatsapp from '../../assets/icons/whatsapp1.png';
import linkedin from '../../assets/icons/linkedin.png';
import tiktok from '../../assets/icons/tiktok.png';
import x from '../../assets/icons/x.png';
import phone from '../../assets/icons/phone1.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink } from 'react-router-hash-link';
const MainNav = () => {
    return (
        <Navbar collapseOnSelect className="color-nav-lg fixed-top" expand="lg" variant="dark">
        <Container className="nav-c">
          <Navbar.Brand className="logo-nav" href="/" as={Link} to={"/#"}>
            <img
              focusable="false"
              className="nayanlogo"
              src={nayanlogo}
              alt="nayanlogo"
              width="110"
              height="50"
            ></img>
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="color-nav-xl" id="responsive-navbar-nav">
          <Nav>
             <Nav.Link className="text-link" href="/" as={Link} to={"/"} >الرئيسية</Nav.Link>
              <HashLink className="text-link nav-link" to="/#OurProducts">مشاريعنا</HashLink>
             <HashLink   className="text-link nav-link" to="/#AboutUs">عن نيان</HashLink>
              <HashLink  className="text-link nav-link" to="/#News">أخبار العقار</HashLink>
              <HashLink className="text-link nav-link" to="/#Partners">شركائنا</HashLink>
          </Nav>
          <Nav className="social-links mr-0">
              <Nav.Link target='_blank' href='https://api.whatsapp.com/send/?phone=966561291512&text&type=phone_number&app_absent=0'>
                <img className="social"
                src={whatsapp}
                alt=""
                width="36"
                height="36"></img> 
              </Nav.Link>
              <Nav.Link target='_blank' href="https://www.instagram.com/nayan_ltd">
                <img className="social"
                src={instagram}
                alt=""
                width="36"
                height="36"></img>
              </Nav.Link>
              <Nav.Link target='_blank' href="https://x.com/nayan_ltd">
                <img className="social"
                src={x}
                alt=""
                width="36"
                height="36"></img>
              </Nav.Link>
              <Nav.Link target='_blank' href="https://www.linkedin.com/company/mrac-nayan/">
                <img className="social"
                src={linkedin}
                alt=""
                width="36"
                height="36" ></img>
              </Nav.Link>
              <Nav.Link target='_blank' href="https://www.tiktok.com/@nayan_ltd">
                <img className="social"
                src={tiktok}
                alt=""
                width="36"
                height="36"></img>
              </Nav.Link>
              <Nav.Link target='_blank' href="tel:+966561291512">
                <img className="social"
                src={phone}
                alt=""
                width="36"
                height="36" ></img>
              </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
    ) 
  }
  
  export default MainNav
  